<template>
  <div class="row layouts">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row">
            <b-col>
              <router-link :to="{ path :'/layouts'}"
                class="btn btn-sm btn-primary">
                <i class="fa fa-backward"></i> Back
              </router-link>
            </b-col>
            <b-col class="text-right">
              <router-link :to="{ path :'/layouts/create?id='+this.layoutID}"
                class="btn btn-sm btn-primary mb-1">
                <i class="fa fa-pencil"></i>
              </router-link>
              <label for="" class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">{{ formatPriceBasic(totalRows) }}</strong>
            </b-col>
          </div>
          <b-table
            sticky-header="500px"
            responsive="sm"
            hover
            striped
            :items="items"
            :busy="isLoading"
            :isLoading="isLoading"
            :fields="fields"
            show-empty
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1)}}
            </template> 
            <template #cell(name)="row">
              <div class="text-left">
								{{ row.item.name }}
              </div>
            </template>
						<template #cell(action)="row">
              <button class="btn btn-sm btn-danger" @click="actionDelete(row.item)">
								<i class="fa fa-trash-o"></i>
							</button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <svg class="heart" viewBox="0 0 32 29.6">
                  <path
                    d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                    c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                  />
                </svg>
                Loading ...
              </div>
            </template>
          </b-table>
        </div>
				<b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1 mt-2 pt-4">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import API from "../../plugins/http";
export default {
  name: "UserBanned",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: null,
      isLoadingSearch: false,
      items_search: [],
      layoutID:0,
      fields: [
        { key:'No', label: 'No'},
        { key:'name', label: 'Name'},
        { key:'brand_name', label: 'Brand '},
        { key:'sort_priority', label: 'Sort Priority'}, 
				{ key:'action', label: 'Delete'}
      ]
    };
  },
  watch: {
    currentPage: function() {
      this.actionFetchLayouts();
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.productChoices.isLoading,
      isError: (state) => state.productChoices.isError,
      totalRows: (state) => state.productChoices.totalRows,
			items: (state) => state.productChoices.items,
    }),
  },
  mounted() {
    this.actionFetchLayouts();
  },
  methods: {
    ...mapActions("productChoices", ["fetchLayout","fetchLayoutByID"]),
    ...mapMutations("users", ["setItems", "setRows"]),
    actionFetchLayouts() {
			this.layoutID = this.$route.params.id 
			let payload = {
				id : this.layoutID
			}
      this.fetchLayoutByID(payload);
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
		actionDelete(payload) {
      console.log(payload)
      let body = {
        layout_id: payload.id,
        offer_id: payload.offer_id
      }
      console.log(body)
      API.post(`/product-choices/delete/${payload.id}`,body)
        .then((response) => {
            let data =response.data
            this.$swal({
              toast: "true",
              position: "top-end",
              icon: data.success ? "success" : "error",
              title: data.message,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.actionFetchLayouts();
        })
        .catch(err => {
          let error = err.response.data
          this.$swal({
              toast: "true",
              position: "top-end",
              icon: "error",
              title: error.message,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
        })
		}
  },
};
</script>

<style scoped>
@import '../../assets/css/table.css';
</style>
